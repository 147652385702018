import qs from 'query-string'
import {
  useState,
  useEffect,
  useCallback
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useApiClient } from './use-api-client'
import { saveSignupState } from '../../signup/lib/signup-state'
import { useSubscriptionIframe } from './use-subscription-iframe'
import { MODALS } from '../../../constants'
import {
  links, signupSequence,
  paymentCopy, planType
} from '../subscription-constants'
import { useAddPaymentMethodIframe } from './use-add-payment-method-iframe'
import { getModalLocation } from '../../../lib/modal'
import { useRedirectSignUp } from './use-redirect-sign-up'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { usePlanDetails } from './use-plan-details'
import { segmentTrackInitiatePurchase } from '../../../segment/segment-track'

/**
 * Responsible for fetching and providing data to
 * the subscription payment screen.
 */
/* eslint-disable import/prefer-default-export */
export const useSubscriptionPayment = () => {
  const [loading, setLoading] = useState(true)
  const [serverData, setServerData] = useState({
    iframeUrl: '',
    details: []
  })

  const location = useLocation()
  const history = useHistory()
  const { checkLocationToRedirect, isInSignupFlow } = useRedirectSignUp(signupSequence.payment)
  const { getPlanDetailById } = usePlanDetails()

  const { voucher, plan } = qs.parse(location.search)
  const isAnnuallyPlan = plan === planType.ANNUAL
  const isTvodPlan = plan === planType.TVOD
  const isStandardPlan = plan === planType.STANDARD
  const isBasicPlan = plan === planType.BASIC

  const {
    getSubscriptionPaymentDetails,
    doesUserHaveAnActiveSubscription,
    addPaymentMethod
  } = useApiClient()

  const getPlanDetails = usePlanChangeFlag()

  const onSuccess = useCallback(orderId => {
    history.push({
      ...location,
      pathname: links.confirmation,
      search: qs.stringify({
        ...qs.parse(location.search),
        orderId
      })
    })
  }, [])

  /**
   * Handler for tvod user add payment method successfully
   * @type {function(*): void}
   */
  const onAddCardSuccessHandler = useCallback((status) => {
    // push to confirmation page
    if (status === 'success') {
      history.push({
        ...location,
        pathname: links.confirmation,
        search: qs.stringify({
          ...qs.parse(location.search),
          status
        })
      })
    }
  }, [])

  /**
   * Handler for tvod user cancel add payment method
   * @type {function(): void}
   */
  const onAddCancelCancelHandler = useCallback(() => {
    history.replace(
      getModalLocation(location, MODALS.qsParams.signupTVODCancelAddPayment)
    )
  }, [])

  let iframeRefByPlan
  let promotionState = false
  if (isTvodPlan) {
    const { iframeRef: addPaymentIframeRef } = useAddPaymentMethodIframe(onAddCardSuccessHandler, onAddCancelCancelHandler)
    iframeRefByPlan = addPaymentIframeRef
  } else {
    const { iframeRef: subscriptionRef, promotionRejected } = useSubscriptionIframe(onSuccess)
    iframeRefByPlan = subscriptionRef
    promotionState = promotionRejected
  }

  useEffect(() => {
    checkLocationToRedirect()

    const loadServerData = async () => {
      try {
        const hasSubscription = await doesUserHaveAnActiveSubscription()

        if (hasSubscription) {
          onSuccess(200)
          return
        }

        let data
        if (isTvodPlan) {
          const addPaymentIframe = await addPaymentMethod()
          data = {
            iframe: addPaymentIframe,
            details: []
          }
        } else {
          let subscriptionId = ''
          if (isAnnuallyPlan) {
            subscriptionId = getPlanDetails?.annual.id
          } else if (isStandardPlan) {
            subscriptionId = getPlanDetails?.standard.id
          } else if (isBasicPlan) {
            subscriptionId = getPlanDetails?.basic.id
          }

          data = await getSubscriptionPaymentDetails(
            subscriptionId,
            voucher || null,
            true,
            null
          )

          // Add segment data analytics for initiating payment
          const selectedPlan = getPlanDetailById(subscriptionId)
          const sku = selectedPlan?.sku
          const paymentDue = data?.details[0]?.value
          const payment = Number(data?.details[1]?.value?.replace('$', ''))
          segmentTrackInitiatePurchase({
            ...selectedPlan,
            sku,
            paymentDue,
            payment,
            voucher
          })
        }

        setServerData(data)
        setLoading(false)
      } catch (error) {
        history.push(`${links.error}/${error.message}`)
      }
    }

    !isTvodPlan && isInSignupFlow && saveSignupState(voucher, plan)
    loadServerData()
  }, [])

  const getPaymentCopy = () => {
    if (voucher || (plan === planType.STANDARD)) return paymentCopy.standard
    if (isBasicPlan) return paymentCopy.basic
    if (isAnnuallyPlan) return paymentCopy.annual
    if (plan === planType.TVOD) return paymentCopy.tvod
    return paymentCopy.standard
  }

  const copy = promotionState || getPaymentCopy()

  let staticDetail = null
  if (isAnnuallyPlan) {
    staticDetail = getPlanDetails?.annual.staticDetail
  } else if (isStandardPlan) {
    staticDetail = getPlanDetails?.standard.staticDetail
  } else if (isBasicPlan) {
    staticDetail = getPlanDetails?.basic.staticDetail
  }

  const nonTVODPlanDetails = [
    staticDetail,
    ...serverData.details
  ]
  const detail = isTvodPlan ? serverData.details : nonTVODPlanDetails

  return {
    plan,
    copy,
    detail,
    loading,
    iframeRef: iframeRefByPlan,
    promotionRejected: promotionState,
    iframeUrl: serverData.iframe,
    showVoucherMessage: Boolean(voucher),
    isTvodPlan,
    isAnnuallyPlan,
    isStandardPlan
  }
}
