import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Input from './input'
import Loader from '../loader'
import Checkbox from './checkbox'
import Select from '../../../../components/forms/select'
import VoucherInput from './voucher-input'
import PasswordInput from './password-input'

import { links } from '../../../shared/subscription-constants'
import { useFocusOnMount } from '../../hooks/use-focus-on-mount'
import { useConfigQuery } from '../../../../hooks/use-config-query'
import { useCreateAccountForm } from '../../hooks/use-create-account-form'
import getBirthYearOptions from '../../../../utils/getBirthYearOptions'
import getDropdownValue from '../../../../utils/getDropdownValue'
import getGenderOptions from '../../../../utils/getGenderOptions'
import { useStyles } from './create-account-form.styles'

/**
 * Renders a form to allow the user to create
 * an account on the platform.
 */
const CreateAccountForm = ({ isAuthenticated }) => {
  const styles = useStyles()
  const firstElementRef = useFocusOnMount()
  const { configValues } = useConfigQuery()
  const birthYearOptions = getBirthYearOptions(configValues, true)
  const genderOptions = getGenderOptions()

  const {
    values,
    errors,
    submitting,
    handleGATrack,
    handleChange,
    handleDropdownChange,
    handleSubmit
  } = useCreateAccountForm(isAuthenticated)

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
        <Input
          label="First Name"
          value={values.firstName}
          error={errors.firstName}
          name="firstName"
          type="text"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          ref={firstElementRef}
          data-e2e="first-name-signup"
          autoComplete="new-off"
        />

        <Input
          label="Last Name"
          value={values.lastName}
          error={errors.lastName}
          name="lastName"
          type="text"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          data-e2e="last-name-signup"
          autoComplete="new-off"
        />

        <Input
          label="Email"
          value={values.email}
          error={errors.email}
          name="email"
          type="email"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          data-e2e="email-signup"
          autoComplete="new-off"
        />

        <Input
          label="Confirm Email"
          value={values.confirmEmail}
          error={errors.confirmEmail}
          name="confirmEmail"
          type="email"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          data-e2e="email-confirm-signup"
          autoComplete="new-off"
        />

        <PasswordInput
          label="Password"
          value={values.password}
          error={errors.password}
          name="password"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          data-e2e="password-signup"
          autoComplete="new-off"
        />

        <Select
          inline
          name="birthYear"
          label="Year of birth"
          value={getDropdownValue(values.birthYear)}
          warning={errors.birthYear}
          warningClass={styles.visibleFieldError}
          options={birthYearOptions}
          onBlur={handleGATrack}
          onChange={(e) => handleDropdownChange(e, 'birthYear')}
          disabled={submitting}
          data-e2e="year-of-birth-signup"
          labelClass={styles.label}
          exclamationClass={styles.noExclamation}
          darkTheme
          hasTooltip
        />

        <Select
          wrapperClass={styles.dropdown}
          inline
          name="gender"
          label="Gender"
          value={getDropdownValue(values.gender)}
          warning={errors.gender}
          warningClass={styles.visibleFieldError}
          options={genderOptions}
          onBlur={handleGATrack}
          onChange={(e) => handleDropdownChange(e, 'gender')}
          disabled={submitting}
          data-e2e="gender-signup"
          labelClass={styles.label}
          exclamationClass={styles.noExclamation}
          darkTheme
          hasTooltip
        />

        <div className={styles.ageAndGenderPolicy}>
          <span>
            NEON will use your data to personalise and improve your NEON experience and to send you information about NEON.
            You can change your communication preferences at any time. We may use your data as described in our
            {' '}
            <NavLink
              to={links.privacy}
              target="_blank"
              className={classNames(styles.link, styles.green)}
            >
              Privacy Policy.
            </NavLink>
          </span>
        </div>

        <Checkbox
          label={(
            <span>
              I agree to the Neon
              {' '}
              <NavLink
                to={links.terms}
                className={classNames(styles.link, styles.green)}
              >
                Terms of Use
              </NavLink>
              {' '}
              and
              {' '}
              <NavLink
                to={links.privacy}
                className={classNames(styles.link, styles.green)}
              >
                Privacy Policy
              </NavLink>
            </span>
          )}
          checked={!!values.acceptTerms}
          error={errors.acceptTerms}
          onChange={handleChange}
          disabled={submitting}
          className={classNames(styles.topSpace, styles.bottomSpace)}
          name="acceptTerms"
          data-e2e="terms-signup-checkbox"
        />

        <Checkbox
          label={
            <span>I am happy to receive emails promoting Neon services</span>
          }
          checked={!!values.optInToNewsletter}
          error={errors.optInToNewsletter}
          onChange={handleChange}
          onClick={handleGATrack}
          disabled={submitting}
          className={styles.bottomSpace}
          name="optInToNewsletter"
          data-e2e="newsletter-signup-checkbox"
        />

        <VoucherInput
          label="Add your voucher code (Optional)"
          value={values.voucherCode}
          error={errors.voucherCode}
          name="voucherCode"
          type="text"
          onBlur={handleGATrack}
          onChange={handleChange}
          disabled={submitting}
          data-e2e="voucher-signup-input"
          autoComplete="new-off"
        />

        <div className={styles.formGroup}>
          <input
            type="submit"
            className={styles.submit}
            value="Create your account"
            data-e2e="create-account-button-signup-submit"
          />

          <p
            className={classNames(styles.formError, {
              [styles.visible]: Boolean(errors.formError)
            })}
          >
            {errors.formError}
          </p>
        </div>
      </form>

      <Loader visible={submitting} data-e2e="signup-loader-visible" />
    </div>
  )
}

CreateAccountForm.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default CreateAccountForm
