import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'

import TempPriceAlert from '../components/temp-price-alert/temp-price-alert'
import { LoadingComponent } from '../../../components/loading'
import ProgressDots from '../components/progress-dots'
import { signup } from '../../../lib/analytics/events'
import { useApiClient } from '../../shared/hooks/use-api-client'
import { useAuth } from '../../shared/hooks/use-auth'
import useStyles from './screen.styles'
import PlanSelectionStandardViewNew
  from '../components/plan-selection-standard-view/plan-selection-standard-view-new'
import PlanSelectionMediumView
  from '../components/plan-selection-medium-view/plan-selection-medium-view'
import { signupSequence } from '../../shared/subscription-constants'
import { useSelectPlan } from '../hooks/use-select-plan'
import VOUCHER_WITH_SKU_QUERY from '../../../../graphql/queries/voucher-with-sku.gql'
import { getGQLErrorCode, getGQLErrorMsg, getGQLErrorDetails } from '../../../lib/apollo'
import { MW_ERRORS, MODALS } from '../../../constants'
import { setVoucherValidateError, setVoucherValidateType } from '../../../actions'
import { usePlanDetails } from '../../shared/hooks/use-plan-details'

const PlanSelectionWithOffer = () => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { voucher } = qs.parse(location.search)
  const [loading, setLoading] = useState(true)
  const [activeSubscription, setActiveSubscription] = useState(null)
  const { isAuthenticated } = useAuth()
  const { doesUserHaveAnActiveSubscription } = useApiClient()
  const { selectToNext } = useSelectPlan()
  const dispatch = useDispatch()
  const { getPlanTitleBySku, getPlanSkuByType } = usePlanDetails()
  const [
    validateVoucherApplicable,
    { data: validateVoucherData, loading: validateVoucherLoading, error: validateVoucherError }
  ] = useLazyQuery(VOUCHER_WITH_SKU_QUERY)

  // Handle voucher applicable validation VOUCHER_WITH_SKU_QUERY response
  useEffect(() => {
    if (validateVoucherError) {
      const errorCode = validateVoucherError.graphQLErrors && getGQLErrorCode(validateVoucherError)
      const errorDetails = validateVoucherError.graphQLErrors && getGQLErrorDetails(validateVoucherError)
      const errorMessage = validateVoucherError.graphQLErrors
        ? getGQLErrorMsg(validateVoucherError)
        : validateVoucherError.message

      if (errorCode === MW_ERRORS.VOUCHER_NOT_APPLICABLE) {
        dispatch(setVoucherValidateError(errorMessage))
        dispatch(setVoucherValidateType(getPlanTitleBySku(errorDetails?.voucherSku)))
        const searchQuery = { ...qs.parse(location.search) }
        searchQuery.plan = activeSubscription
        // Show error modal
        searchQuery.modal = MODALS.qsParams.voucherValidateErrorForSignup
        history.replace({
          ...location,
          search: qs.stringify(searchQuery)
        })
      }
    }

    if (validateVoucherData && !validateVoucherLoading) {
      dispatch(setVoucherValidateError(''))
      dispatch(setVoucherValidateType(''))
      // Go to next step if voucher applicable successfully validated
      selectToNext(activeSubscription)
    }
  }, [validateVoucherData, validateVoucherLoading, validateVoucherError])

  const checkIfUserHasActiveSubscription = async () => {
    const serverData = await doesUserHaveAnActiveSubscription()
    setLoading(false)

    if (serverData) {
      // when user is authenticated and has an active subscription, redirect back to home page
      history.push('/')
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      checkIfUserHasActiveSubscription()
    } else {
      // when user is not authenticated, redirect back to home page
      history.push('/')
    }

    signup.planSelectionSeen(signupSequence.planSelection)
  }, [])

  const nextStep = async (subscription) => {
    setActiveSubscription(subscription)
    if (voucher && subscription) {
      validateVoucherApplicable({
        variables: {
          voucherCode: voucher,
          signupSku: getPlanSkuByType(subscription)
        }
      })
    } else {
      // Go to next step if no voucher
      selectToNext(subscription)
    }
  }

  if (loading) {
    return <LoadingComponent pastDelay />
  }

  return (
    <>
      <TempPriceAlert />
      <ProgressDots steps={3} currentStep={signupSequence.planSelection} />

      <div className={styles.container}>
        <h1 className={styles.title}>Welcome to Neon</h1>

        <PlanSelectionStandardViewNew selectToNext={nextStep} />
        <PlanSelectionMediumView selectToNext={nextStep} />
      </div>
    </>
  )
}

export default PlanSelectionWithOffer
