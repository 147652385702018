import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import { links, signupSequence } from '../../shared/subscription-constants'
import { FORM_MESSAGES, MODALS } from '../../../constants'
import { STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE, STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../storage-keys'
import { signup } from '../../../lib/analytics/events'
import { useRedirectSignUp } from '../../shared/hooks/use-redirect-sign-up'
import {
  setVoucherValidateError,
  setVoucherValidateType,
  setSignupFormValue,
  initSignupFormValue,
  login
} from '../../../actions'
import { segmentTrackSignup } from '../../../segment/segment-track'
import { segmentIdentify } from '../../../segment/segment-identify'
import ACCOUNT_MUTATION from '../../../../graphql/mutations/account.gql'
import VOUCHER_WITHOUT_SKU_QUERY from '../../../../graphql/queries/voucher-without-sku.gql'
import { getGQLErrorCode, getGQLErrorMsg } from '../../../lib/apollo'
import { initialValuesForAvod, schemaForAvod } from '../components/create-account-form/form-schema-for-avod'

/**
 * Responsible for orchastrating the create account form. Provides
 * handlers for change, blur, etc for each field defined in the schema.
 *
 * @param {boolean} isAuthenticated check user is isAuthenticated or not
 */

export const useCreateAccountForm = (isAuthenticated) => {
  const [stepSeen, setStepSeen] = useState(false)
  const [values, setValues] = useState(initialValuesForAvod)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const mapState = useSelector(state => state)
  const { signupFormValue } = mapState
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { checkLocationToRedirect } = useRedirectSignUp(signupSequence.createAccount)
  const [
    accountMutation,
    { data: accountMutationData, loading: accountMutationLoading, error: accountMutationError }
  ] = useMutation(ACCOUNT_MUTATION)
  const [
    voucherWithoutSkuQuery,
    { data: voucherWithoutSkuQueryData, loading: voucherWithoutSkuQueryLoading, error: voucherWithoutSkuQueryError }
  ] = useLazyQuery(VOUCHER_WITHOUT_SKU_QUERY, {
    fetchPolicy: 'network-only'
  })

  const accountMutationVariables = {
    variables: {
      input: {
        name: values.firstName,
        surname: values.lastName,
        email: values.email,
        password: values.password,
        birthYear: values.birthYear,
        gender: values.gender,
        optIns: [
          {
            id: 'RECEIVE_UPDATES',
            subscribed: values.optInToNewsletter
          }
        ]
      }
    }
  }

  if (isAuthenticated) {
    checkLocationToRedirect()
    history.push({
      ...location,
      pathname: links.planSelection
    })
  }

  if (!stepSeen && !isAuthenticated) {
    signup.createAccountSeen(signupSequence.createAccount)

    setStepSeen(true)
  }

  // Initialize form values
  useEffect(() => {
    if (Object.keys(signupFormValue).length > 0) {
      const newValues = {
        ...initialValuesForAvod,
        ...signupFormValue
      }

      // Initialize email which get from the guest home page emailBox if it exists
      const existingEmail = sessionStorage.getItem(STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE)
      if (existingEmail) {
        newValues.email = existingEmail
      }

      setValues(newValues)
    }
  }, [])

  // Set form errors that come from handleChange and handleSubmit
  useEffect(() => {
    if (errors && errors.userExists) {
      history.replace({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          modal: MODALS.qsParams.userExists,
          email: values.email
        })
      })
    }
  }, [errors])

  // Handle voucher validation VOUCHER_WITHOUT_SKU_QUERY response
  useEffect(() => {
    if (voucherWithoutSkuQueryError) {
      setSubmitting(false)
      const errorMessage = voucherWithoutSkuQueryError.graphQLErrors
        ? getGQLErrorMsg(voucherWithoutSkuQueryError)
        : voucherWithoutSkuQueryError.message

      const submitErrors = {
        voucherCode: errorMessage,
        formError: FORM_MESSAGES.voucherInvalid
      }

      reportValidationErrors(submitErrors)
      setErrors(submitErrors)
    }

    if (voucherWithoutSkuQueryData && !voucherWithoutSkuQueryLoading) {
      trackVoucherCode()

      const { voucherWithoutSku } = voucherWithoutSkuQueryData
      if (voucherWithoutSku?.applicablePlans) {
        sessionStorage.setItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, voucherWithoutSku?.applicablePlans?.toString())
      }

      // Create account if voucher has been validated successfully
      accountMutation(accountMutationVariables)
    }
  }, [voucherWithoutSkuQueryData, voucherWithoutSkuQueryLoading, voucherWithoutSkuQueryError])

  // Handle create account ACCOUNT_MUTATION response
  useEffect(() => {
    if (accountMutationError) {
      setSubmitting(false)
      const errorCode = accountMutationError.graphQLErrors && getGQLErrorCode(accountMutationError)
      const errorMessage = accountMutationError.graphQLErrors
        ? getGQLErrorMsg(accountMutationError)
        : accountMutationError.message

      let submitErrors = {
        formError: errorMessage
      }

      if (
        errorCode &&
        ['USER_EXISTS', 'INVALID_CREDENTIALS'].includes(errorCode)
      ) {
        submitErrors = {
          userExists: errorMessage,
          formError: errorMessage
        }
      }

      reportValidationErrors(submitErrors)
      setErrors(submitErrors)
    }

    if (accountMutationData && !accountMutationLoading) {
      const { account } = accountMutationData
      // Add segment data identify for sign up
      const currentProfile = account?.profiles?.find(item => item.id === account?.selectedProfile)
      segmentIdentify(account, currentProfile)
      // Add segment data analytics for sign up
      segmentTrackSignup({
        email: values.email,
        voucher: values.voucherCode,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        birthYear: values.birthYear
      })

      dispatch(initSignupFormValue())
      dispatch(login(account, true))
      dispatch(setVoucherValidateError(''))
      dispatch(setVoucherValidateType(''))
    }
  }, [accountMutationData, accountMutationLoading, accountMutationError])

  const validateForm = (valuesToValidate, touchedFields) => {
    try {
      schemaForAvod.validateSync(valuesToValidate, { abortEarly: false })
      return {}
    } catch (err) {
      const validationErrors = {}

      err.inner.forEach(validationError => {
        const { path, message } = validationError

        if (!validationErrors[path] && touchedFields[path]) validationErrors[path] = message
      })

      return validationErrors
    }
  }

  const forceTouchAllFields = () => {
    const fieldNames = Object.keys(values)
    return fieldNames.reduce(
      (touchedFields, fieldName) => ({
        ...touchedFields,
        [fieldName]: true
      }),
      {}
    )
  }

  const updateState = (newValues, newTouched, newErrors) => {
    setValues(newValues)
    dispatch(setSignupFormValue(newValues))
    setTouched(newTouched)
    setErrors(newErrors)
  }

  const reportValidationErrors = errorsToReport => {
    const errorTypes = Object.keys(errorsToReport)
    const sequence = signupSequence.createAccount

    errorTypes.forEach(errorType => {
      signup.validationError(`${errorType}: ${errorsToReport[errorType]}`, sequence)
    })
  }

  // Add voucherCode into url for selecting plan in next step
  const trackVoucherCode = () => {
    const { voucherCode } = values
    const parsedSearch = qs.parse(location.search)
    voucherCode && history.replace({
      ...location,
      search: qs.stringify({
        ...parsedSearch,
        voucher: voucherCode
      })
    })
  }

  const handleChange = e => {
    // Set values
    const { target } = e

    const newValue = oldValue => (target.type === 'checkbox' ? !oldValue : target.value)

    const newValues = {
      ...values,
      [target.name]: newValue(values[target.name])
    }

    const newTouched = {
      ...touched,
      [target.name]: true
    }

    const newErrors = validateForm(newValues, newTouched)
    updateState(newValues, newTouched, newErrors)

    if (target.type === 'checkbox') {
      signup.fieldComplete(target.name, target.checked, signupSequence.createAccount)
    }
  }

  const handleDropdownChange = (value, name) => {
    const newValues = {
      ...values,
      [name]: value.value
    }

    const newTouched = {
      ...touched,
      [name]: true
    }

    const newErrors = validateForm(newValues, newTouched)
    updateState(newValues, newTouched, newErrors)
  }

  /**
   * Binding event to push data to GA
   */
  const handleGATrack = event => {
    const { target } = event
    const sequence = signupSequence.createAccount

    const fieldName = target.name
    const error = errors[fieldName]
    const isTouched = touched[fieldName]

    // for text field
    if (!isTouched && target.type !== 'checkbox') return

    if (error) {
      signup.validationError(`${fieldName}: ${error}`, sequence)
      return
    }

    const fieldValue =
      target.type === 'checkbox' ? { value: target.checked ? 'yes' : 'no' } : undefined

    signup.fieldComplete(fieldName, fieldValue, sequence)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)

    const newTouched = forceTouchAllFields()
    const newErrors = validateForm(values, newTouched)

    if (Object.keys(newErrors).length > 0) {
      setSubmitting(false)

      reportValidationErrors(newErrors)
      updateState(values, newTouched, {
        ...newErrors,
        formError: FORM_MESSAGES.validationError
      })

      return
    }

    const { voucherCode } = values
    // Clear the voucher info in session storage
    sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)

    if (voucherCode && voucherCode !== '') {
      // validate voucher then save voucher for plan selection
      voucherWithoutSkuQuery({
        variables: {
          voucherCode
        }
      })
    } else {
      // Create Account if no voucher
      accountMutation(accountMutationVariables)
    }
  }

  return {
    values,
    errors,
    submitting,
    handleChange,
    handleDropdownChange,
    handleSubmit,
    handleGATrack
  }
}

export default useCreateAccountForm
