import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  container: {
    alignSelf: 'stretch',
    padding: [0, 20, 20],
    marginBottom: 80
  },

  form: {
    maxWidth: 580,
    margin: 'auto',
    fontFamily: 'GothamRounded, Helvetica, Arial, sans-serif'
  },

  formGroup: {
    margin: 0,
    paddingBottom: 6
  },

  topSpace: {
    marginTop: 5
  },

  bottomSpace: {
    marginBottom: 5
  },

  divider: {
    width: '100%',
    height: 1,
    margin: [5, 0, 15],
    backgroundColor: theme.field
  },

  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.8,
    color: '#eeeeee',
    display: 'block',
    position: 'relative'
  },

  input: {
    display: 'block',
    width: '100%',
    fontSize: 15,
    lineHeight: 1.07,
    padding: 12,
    color: theme.white,
    borderRadius: 4,
    background: theme.field,
    outline: 'none',
    border: '1px solid transparent',
    transition: 'all 0.125s',

    '&:focus': {
      background: theme.fieldHighlight,
      border: '1px solid #979797',
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.35), 0 2px 4px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(0, 0, 0, 0.5)'
    },

    '&:autofill': {
      transitionProperty: theme.field,
      transitionDelay: '86400s', // Do NOT start the property's transition effect for 24 hours
      backgroundImage: 'none !important'
    }
  },

  togglePasswordButton: {
    top: 25,
    right: 1,
    position: 'absolute',
    padding: [14, 10, 13]
  },

  inputError: {
    borderColor: theme.error,

    '&:focus': {
      borderColor: theme.error
    }
  },

  checkboxLabel: {
    cursor: 'pointer',
    paddingLeft: 28
  },

  checkbox: {
    opacity: 0,
    zIndex: -1,
    position: 'absolute',

    '&:focus ~ $checkboxIndicator': {
      backgroundColor: theme.fieldHighlight,
      border: '1px solid #979797'
    }
  },

  checkboxIndicator: {
    borderRadius: 4,
    backgroundColor: theme.field,
    border: '1px solid transparent',
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    width: 20,
    height: 20,
    backgroundSize: '75% 75%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.125s'
  },

  checked: {
    backgroundImage: 'url(\'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14"%3E%3Cpath fill="%23CAFD34" fill-rule="evenodd" d="M15.84 0L5.76 9.8 2.16 6.3 0 8.4 5.76 14 18 2.1z"/%3E%3C/svg%3E%0A\')'
  },

  fieldError: {
    visibility: 'hidden',
    display: 'inline-block',
    color: theme.error,
    fontSize: 10,
    margin: [2, 0, 0]
  },

  visibleFieldError: {
    display: 'inline-block',
    color: theme.error,
    fontSize: 10,
    margin: [6, 0, 0]
  },

  submit: {
    width: '100%',
    fontSize: 16,
    padding: [12, 0],
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.black,
    background: theme.neonGreen,
    border: 'none',
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [10, 0],

    '&:hover': {
      background: theme.neonGreenLight
    }
  },

  formError: {
    fontSize: 12,
    color: theme.error,
    width: '100%',
    margin: 0,
    textAlign: 'center',
    visibility: 'hidden',
    display: 'inline-block'
  },

  visible: {
    visibility: 'visible'
  },

  link: {
    fontSize: 12,
    color: theme.white,
    transition: 'all 0.125s',
    borderBottom: `1px solid ${theme.white}`,

    '&:hover': {
      color: theme.neonGreen,
      borderColor: theme.neonGreen
    }
  },

  green: {
    color: theme.neonGreen,
    borderColor: theme.neonGreen,

    '&:hover': {
      color: theme.neonGreenLight,
      borderColor: theme.neonGreenLight
    }
  },

  dropdown: {
    padding: '15px 0'
  },

  noExclamation: {
    visibility: 'hidden'
  },

  ageAndGenderPolicy: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingBottom: 25
  }
}))
